/* @import '~antd/dist/antd.css'; */

@import '~antd/dist/antd.less';

html, body {
  height: 100%;
}

.ant-layout-header {
  padding: 0;
}

.ant-menu-item {
  margin-top: 0 !important;
}

h1 {
  font-size: 20px;
}

h2 {
  font-size: 15px;
}

.ant-btn {
  border-radius: 10px;
}

.ant-menu {
  color: red;
}
@primary-color: #325d79;@link-color: #325d79;